import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import './App.css';
import WebsitePlaceholder from "./components/WebsitePlaceholder";

export default class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route path='*' element={<WebsitePlaceholder/>}/>
                </Routes>
            </Router>
        );
    }
}
