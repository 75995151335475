import React from "react";
import {ReactComponent as Logo} from './shared/dimini-logo.svg';

function WebsitePlaceholder() {
    return (
        <div>
            <div id="text">
                <Logo fill='red' stroke='green'/><h1>Dimini<span>®</span></h1>
            </div>
            <canvas id="canvas"></canvas>
            <div id='constructionNotification'
                 style={{zIndex: '100',
                        position: 'relative',
                        background: 'aliceblue',
                        fontSize: '200%',
                        padding: '10px 20px 10px 20px',
                        textAlign: 'center', 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'}}>
                <div style={{background: 'aliceblue'}}>ⓘ</div>
                <div style={{background: 'aliceblue'}}>
                    New website under construction for now. 
                    Visit old site: <a href='https://legacy.dimini.dev'>legacy.dimini.dev</a>
                </div>            
                <div style={{background: 'aliceblue', cursor: 'pointer'}} 
                     onClick={(e) => document.getElementById('constructionNotification').style.display = 'none'}>
                        ×
                </div>
            </div>
        </div>
    );
}

export default WebsitePlaceholder;